import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SitemapPage.scss';

import { SitemapCategoryProps } from 'components/SitemapCategory/models';
import Layout from 'components/Layout';
import SitemapCategory from 'components/SitemapCategory';
import { graphql } from 'gatsby';

const SitemapPage = ({
  data: {
    sitemap: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, title },
    faq,
    allLds: { edges: lds },
    products: { title: productsTitle, url: productsUrl, id: productsId, products },
    allAboutSubPages: { nodes: aboutSubPages },
    allAboutParentPage: { nodes: aboutParentPage },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) => {
  const crumbsSettings = {
    crumbs,
  };

  const categories: SitemapCategoryProps[] = [
    {
      name: faq.name,
      link: faq.url,
      id: faq.id,
    },
    {
      name: aboutParentPage[0].title,
      link: aboutParentPage[0].link,
      id: aboutParentPage[0].id,
      subcategories: aboutSubPages.map((page) => ({
        name: page.title,
        link: page.link,
        id: page.id,
      })),
    },
    ...lds.map(({ node: { fields: { slug }, title: ldsTitle, id } }) => ({
      name: ldsTitle,
      link: `/${slug}`,
      id,
    })),
    {
      name: productsTitle,
      link: productsUrl,
      id: productsId,
      subcategories: products.map(({ name, url: link }) => {
        return { name, link };
      }),
    },
  ];

  return (
    <Layout
      crumbsSettings={crumbsSettings}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords }}
    >
      <Container fluid className="sitemap">
        <h1 className="sitemap__title">{title}</h1>
        <Row>
          <Col lg={{ span: 12 }}>
            <Row>
              {categories.map((category) => (
                <Col key={category.id} md={4} data-testid="category-column">
                  <SitemapCategory {...category} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default SitemapPage;

export const query = graphql`
  query {
    sitemap {
      title
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
    }
    allLds {
      edges {
        node {
          id
          fields {
            slug
          }
          title
        }
      }
    }
    faq {
      id
      url
      name
    }
    products {
      id
      title
      url
      products {
        name
        url
      }
    }
    allAboutSubPages: allAboutScalp(filter: { hasChildren: { eq: false } }) {
      nodes {
        link
        title
        id
      }
    }
    allAboutParentPage: allAboutScalp(filter: { hasChildren: { eq: true } }) {
      nodes {
        link
        title
        id
      }
    }
  }
`;
