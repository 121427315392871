import React, { FC } from 'react';
import { Link } from 'gatsby';

import { SitemapCategoryProps } from './models';

import './SitemapCategory.scss';

const SitemapCategory: FC<SitemapCategoryProps> = ({ name, link, subcategories }) => (
  <div data-testid="sitemap" className="sitemap-category">
    <span className="sitemap-category__subtitle">
      <Link to={link}>{name}</Link>
    </span>
    {subcategories?.length && (
      <ul data-testid="subcategories">
        {subcategories?.map(({ link: slink, name: sname }) => (
          <li key={slink}>
            <Link to={slink}>{sname}</Link>
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default SitemapCategory;
